<template>
  <div class="file-wrapper">
    <div class="box center search-wrapper">
      <el-form :model="searchForm" :inline="true" ref="searchForm" label-width="85px" class="my-form">
        <el-form-item label="资料类型" prop="type">
          <el-select v-model="searchForm.type" placeholder="请选择">
            <el-option v-for="item in fileTypeOptions" :key="item.id" :label="item.fileTypeName" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="fileName">
          <el-input v-model.trim="searchForm.fileName" placeholder="请输入资料名称"></el-input>
        </el-form-item>
        <el-button @click="handleSearch">查询</el-button>
        <el-button @click="handleReset" class="reset-btn">重置</el-button>
      </el-form>
      <div class="btn-right-wrapper">
        <el-button class="reset-btn" @click="handleTypeManage" :class="!myOption ? 'disable_icon' : ''">资料类型管理</el-button>
        <el-button class="common-btn" @click="handleUploadBtn" :class="!myUpload ? 'disable_icon' : ''">上传资料</el-button>
      </div>
    </div>
    <div class="table-wrapper" v-if="tableData.length">
      <el-table :row-class-name="tableRowClassName" :header-cell-style="{ background: '#04153F', color: '#fff' }" :data="tableData" :height="tableHeight">
        <el-table-column prop="originalName" width="800px" label="名称">
        </el-table-column>
        <el-table-column prop="timeO" label="上传时间"></el-table-column>
        <el-table-column prop="fileTypeName" label="资料类型"></el-table-column>
        <el-table-column prop="type" label="文件类型"></el-table-column>
        <el-table-column label="操作" width="300px">
          <template slot-scope="scope">
            <span class="operate-txt" @click.stop="handleDownload(scope.row)" :class="!myOption ? 'disable_icon' : ''">下载</span>
            <span class="operate-txt" v-show="scope.row.type.indexOf('pdf') >= 0 || scope.row.type.indexOf('jpg') >= 0 || scope.row.type.indexOf('png') >= 0" @click.stop="handleSee(scope.row)" :class="!myOption ? 'disable_icon' : ''">查看</span>
            <span class="operate-txt delete-btn" @click.stop="handleDelete(scope.row)" :class="!myDel ? 'disable_icon' : ''">删除</span>
          </template>
        </el-table-column>
      </el-table>
      <common-pagination v-show="tableData.length > 0" class="pageMain" :pageSize="pageSize" :total="total" :currentPage="currentPage" @handleCurrentChange="handleCurrentChange" />
    </div>
    <div class="noDataMain" v-if="!tableData.length">
      <img src="../../../assets/images/noData.png" alt="" />
      <span>暂无数据</span>
    </div>
    <!-- 查看文件 :width="scaleNum" :style="{ width: scaleNum + 'px' }" -->
    <el-dialog title="查看文件" :visible.sync="pdfVisible"  class="my-dialog pdf-dialog" :before-close="handlePdfClose" top="15vh" custom-class="pdf-class">
      <!-- <div class="pdf-dialog-tltle">
        <span>查看文件</span>
        <img @click="handlePdfClose" src="@/assets/images/all/guanbi_icon.png" alt="" />
      </div> -->
      <div class="pdf-wrapper">
        <!-- <div class="pdf-title" v-show="!downImgUrl">
          <el-button size="small" type="primary" @click="nextPdfPage('prev')">上一页</el-button>
          {{ currentPdfPage }} / {{ pagePdfCount }}
          <el-button size="small" type="primary" @click="nextPdfPage('next')">下一页</el-button>
          <div class="scale">
            <div class="scale-btn" @click="scale('-')">-</div>
            <div class="scale-btn" @click="scale('+')">+</div>
          </div>
        </div> -->
        <div class="pdf-title-warp">
          <div class="pdf-name" >
            <img v-if="pdfName" src="@/assets/images/fragment-title-icon.png" />
            <div v-if="pdfName" class="title">{{ pdfName }}</div>
          </div>
          <div class="pdf-action">
            <div class="page-btn previous-action" @click="nextPdfPage('prev')">
              <i class="el-icon-arrow-left"></i>
              上一页
            </div>
            {{ currentPdfPage }} / {{ pagePdfCount }}
            <div class="page-btn next-action" @click="nextPdfPage('next')">
              下一页
              <i class="el-icon-arrow-right"></i>
            </div>
            <div class="reduce-btn" @click="scale('-')">
              <i class="el-icon-zoom-out"></i>
            </div>
            <div class="amplification-btn" @click="scale('+')">
              <i  class="el-icon-zoom-in"></i>
            </div>
          </div>
        </div>
        <div class="pdf-warp" :style="{ paddingRight:dynamicPadding + 'px',paddingLeft:dynamicPadding + 'px' }">
          <pdf v-show="!downImgUrl" :src="pdfUrl" @num-pages="pagePdfCount = $event" @page-loaded="currentPdfPage = $event" :page="currentPdfPage"></pdf>
          <img v-show="downImgUrl" :src="downImgUrl" alt="" class="down-img" />
        </div>
        <!-- <div class="pdf-btm">
          <span class="sureBtn back-btn pointer mR30" style="padding: 6px 28px" @click="handlePdfClose">关闭</span>
        </div> -->
      </div>
    </el-dialog>
    <!-- 资料类型管理弹窗 -->
    <el-dialog title="资料类型管理" :visible.sync="typeDialogVisible" class="my-dialog add-dialog" top="15vh" :before-close="handleTypeDialogClose">
      <div class="operate-wrapper">
        <div class="add-wrapper">
          <span @click="handleAddClick"><i class="el-icon-circle-plus-outline"></i>新增</span>
        </div>
        <div class="table-wrapper">
          <table class="border-table-seliver">
            <tr>
              <th>资料类型</th>
              <th width="200">操作</th>
            </tr>
            <tr v-for="(item, index) in typeTableData" :key="item.id">
              <td v-show="item.inputFlag">
                <el-input v-model="item.fileTypeName" placeholder="请输入资料类型"></el-input>
              </td>
              <td v-show="!item.inputFlag">{{ item.fileTypeName }}</td>
              <td>
                <span v-show="!item.inputFlag">
                  <span @click="handleEditClick(item, index)" class="edit-btn">编辑</span>
                  <span @click="handleDeleteClick(item, index)">删除</span>
                </span>
                <span v-show="item.inputFlag">
                  <span @click="handleSaveClick(item)" class="edit-btn">保存</span>
                  <span @click="handleCancelClick(item, index)">取消</span>
                </span>
              </td>
            </tr>
          </table>
          <!-- <el-table :row-class-name="tableRowClassName" :header-cell-style="{ background: '#04153F', color: '#fff' }" :data="typeTableData">
                        <el-table-column prop="fileTypeName" label="资料类型"></el-table-column>
                        <el-table-column label="操作">
                            <template slot-scope="scope">
                                <span class="edit-btn">编辑</span>
                                <span>删除</span>
                            </template>
                        </el-table-column>
                        <el-table-column width="30"></el-table-column>
                    </el-table> -->
        </div>
      </div>
    </el-dialog>
    <!-- 上传资料 -->
    <el-dialog title="上传资料" :visible.sync="uploadDialogVisible"  class="my-dialog upload-dialog" top="15vh" :before-close="handleUploadDialogClose">
      <div class="operate-wrapper">
        <el-form :model="uploadForm" :inline="true" :rules="uploadRules" ref="uploadForm" label-width="90px" class="my-form">
          <el-form-item label="资料类型" prop="type">
            <el-select v-model="uploadForm.type" placeholder="请选择">
              <el-option v-for="item in fileTypeOptions" :key="item.id" :label="item.fileTypeName" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="文件" prop="file">
            <el-upload multiple :limit="5" class="upload-demo" :headers="{ token }" ref="upload" :auto-upload="false" :file-list="uploadFileList" :on-remove="handleRemove" :on-change="handleChange" action="">
              <el-button class="upload-btn">选择</el-button>
              <span class="tips">上传文件不超过50M</span>
            </el-upload>
          </el-form-item>
        </el-form>
        <div class="btn-footer">
          <el-button @click="handleUploadSave">保存</el-button>
          <el-button class="cancel-btn" @click="handleUploadDialogClose">取消</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import CommonPagination from "@/components/Pagination";
import axios from "axios";
import pdf from "vue-pdf";
export default {
  props: ["pageId"],
  data() {
    return {
      projectId: sessionStorage.getItem("projectId"),
      token: window.sessionStorage.getItem("token"),
      searchForm: {
        type: "",
        fileName: ""
      },
      // 资料类型
      fileTypeOptions: [],
      tableData: [],
      total: 0,
      pageSize: 10,
      currentPage: 1,
      typeDialogVisible: false,
      // 资料类型设置 表格数据
      typeTableData: [],
      typeTableDataCopy: [],
      uploadDialogVisible: false,
      uploadForm: {
        type: ""
      },
      uploadRules: {
        type: [{ required: true, message: "请选择资料类型", trigger: "blur" }],
        file: [{ required: true, message: "请选择文件", trigger: "blur" }]
      },
      // 上传的文件
      uploadFileList: [],
      pdfVisible: false,
      pagePdfCount: 0,
      currentPdfPage: 1,
      downImgUrl: "",
      pdfUrl: "",
      // 加载中
      loadingPage: null,
      scaleNum: 0,
      scaleArr: ["scale-one", "scale-two", "scale-three"],
      pdfName:"",
      dynamicPadding:500,
      tableHeight:0
    };
  },
  created() {
    console.log("接收到的页面id", this.pageId);
    this.getAllFileTypeData();
    this.getSize();
    window.addEventListener("resize", () =>  { 
      this.getSize();
    }, false);
  },
  mounted() {
    this.getTableData();
  },
  methods: {
    scale(flag) {
      switch (flag) {
        case "+":
          if(this.dynamicPadding == 0){
            return
          }
          this.dynamicPadding = this.dynamicPadding - 100
          break;
        case "-":
          if (this.dynamicPadding == 500) {
            return;
          }
          this.dynamicPadding = this.dynamicPadding + 100
      }
    },
    // 获取资料类型
    getAllFileTypeData() {
      this.$axios.get(`${this.baseURL}file/manage/file/type/list?menuId=${this.pageId}&projectId=${this.projectId}`).then((res) => {
        this.fileTypeOptions = []
        this.fileTypeOptions = [...this.fileTypeOptions,...res.data.data];
      });
    },
    // 获取表格数据
    getTableData() {
      this.$axios.get(`${this.baseURL}file/manage/file/page?fileName=${this.searchForm.fileName}&fileTypeId=${this.searchForm.type}&menuId=${this.pageId}&pageNum=${this.currentPage}&pageSize=${this.pageSize}&projectId=${this.projectId}`).then((res) => {
        console.log("获取表格数据------", res);
        if (res.data.status == "200") {
          let detail = res.data.data;
          this.tableData = detail.records;
          this.total = detail.total;
        }
      });
    },
    // 查询
    handleSearch() {
      this.currentPage = 1;
      this.getTableData();
    },
    // 重置
    handleReset() {
      this.$set(this.searchForm, "type", "");
      this.$set(this.searchForm, "fileName", "");
      this.currentPage = 1;
      this.getTableData();
    },
    // 资料类型管理
    handleTypeManage() {
      if (!this.myOption) {
        this.$message.error("您没有修改权限！");
        return;
      }
      this.$axios.get(`${this.baseURL}file/manage/file/type/list?menuId=${this.pageId}&projectId=${this.projectId}`).then((res) => {
        console.log("获取资料类型表格数据", res);
        this.typeTableDataCopy = res.data.data;
        this.typeTableData = JSON.parse(JSON.stringify(res.data.data));
        this.typeDialogVisible = true;
      });
    },
    // 新增 资料类型管理
    handleAddClick() {
      let obj = {
        fileTypeName: "",
        id: "",
        inputFlag: true, // 是否显示输入框
        addFlag: true // 保存时要判断是新增还是编辑
      };
      this.typeTableData.push(obj);
    },
    // 编辑 资料类型管理
    handleEditClick(item, index) {
      this.typeTableData[index].inputFlag = true;
      this.typeTableData[index].addFlag = false;
      this.$forceUpdate();
    },
    // 保存 资料类型管理
    handleSaveClick(item) {
      console.log(item);
      let data = {
        fileTypeName: item.fileTypeName,
        projectId: this.projectId
      };
      if (item.addFlag) {
        // 新增
        data.menuId = this.pageId;
        this.$axios.post(`${this.baseURL}file/manage/file/type/add`, data).then((res) => {
          if (res.data.status == "200") {
            this.$message.success("保存成功");
            // this.typeDialogVisible = false
            item.inputFlag = false;
            this.getAllFileTypeData();
            this.getTableData();
          } else {
            this.$message.warning(res.data.errMsg);
          }
        });
      } else {
        // 编辑
        data.flag = 0;
        data.id = item.id;
        this.$axios.post(`${this.baseURL}file/manage/file/type/edit`, data).then((res) => {
          if (res.data.status == "200") {
            this.$message.success("保存成功");
            // this.typeDialogVisible = false
            item.inputFlag = false;
            this.getAllFileTypeData();
            this.getTableData();
          } else {
            this.$message.warning(res.data.errMsg);
          }
        });
      }
    },
    // 删除 资料类型管理
    handleDeleteClick(item, index) {
      let data = {
        fileTypeName: item.fileTypeName,
        id: item.id,
        flag: 1,
        projectId: this.projectId
      };
      this.$axios.post(`${this.baseURL}file/manage/file/type/del`, data).then((res) => {
        if (res.data.status == "200") {
          this.$message.success("删除成功");
          this.typeDialogVisible = false;
        } else {
          this.$message.warning(res.data.errMsg);
        }
      });
    },
    // 取消 资料类型管理
    handleCancelClick(item, index) {
      this.typeTableData[index].inputFlag = false;
      if (!item.id) {
        item.fileTypeName = "";
        item.inputFlag = true;
      } else {
        item.fileTypeName = this.typeTableDataCopy[index].fileTypeName;
      }
      this.$forceUpdate();
    },
    // 上传资料
    handleUploadBtn() {
      if (!this.myUpload) {
        this.$message.error("您没有上传权限！");
        return;
      }
      this.uploadForm.type = ""
      this.uploadFileList = []
      this.uploadDialogVisible = true;
    },
    // 上传change事件
    handleChange(file, fileList) {
      // 图片大小限制
      const isLt50M = file.size / 1024 / 1024 <= 50;
      if (!isLt50M) {
        this.$message.error("上传文件不得超过50M");
        fileList.splice(-1, 1);
      } else {
        this.uploadFileList = fileList;
        // this.ruleForm.file_arr = this.uploadFileList
      }
    },
    // 移除文件
    handleRemove() {
      this.handleChange(file, fileList);
    },
    // 上传资料 保存
    handleUploadSave() {
      if (!this.uploadForm.type) {
        this.$message.warning("请选择资料类型");
        return;
      }
      if (!this.uploadFileList.length) {
        this.$message.warning("请上传文件");
        return;
      }
      this.loadingPage = this.$loading({
        lock: true,
        text: "正在上传...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      let formData = new FormData();
      this.uploadFileList.forEach((item) => {
        formData.append("file", item.raw);
      });
      this.$axios.post(`${this.baseURL}file/manage/upload/file?bizId=${this.projectId}&menuId=${this.pageId}&fileTypeId=${this.uploadForm.type}`, formData).then((res) => {
        if (res.data.status == "200") {
          this.uploadDialogVisible = false;
          this.loadingPage.close();
          this.$message.success("上传成功");
          this.getTableData();
        }
      });
    },
    // 查看
    handleSee(row) {
      console.log("dadada",row);
      if (!this.myOption) {
        this.$message.error("您没有查看权限！");
        return;
      }
      let arr = [".png", ".jpg", ".jpeg", ".svg"];
      if (arr.includes(row.type)) {
        this.pdfVisible = true;
        this.downImgUrl = `${this.downloadURL}sys/sysFile/${row.id}`;
        this.pdfName = row.originalName || ""
        return;
      }
      this.pdfVisible = true;
      this.downImgUrl = "";
      this.currentPdfPage = 1;
      this.pdfUrl = pdf.createLoadingTask(`${this.downloadURL}sys/sysFile/${row.id}`);
      this.pdfName = row.originalName || ""
      // this.pdfUrl.promise.then((pdf) => {
      //     console.log("numPages", this.numPages);
      //     this.numPages = pdf.numPages;
      // });
    },
    // 下载
    handleDownload(row) {
      if (!this.myOption) {
        this.$message.error("您没有下载权限！");
        return;
      }
      axios({
        url: `${this.baseURL}sys/sysFile/${row.id}`,
        method: "get",
        responseType: "blob",
        headers: {
          token: this.token
        }
      })
        .then((res) => {
          let a = document.createElement("a");
          let blob = new Blob([res.data]);
          let objectUrl = URL.createObjectURL(blob);
          let name = row.originalName;
          a.setAttribute("href", objectUrl);
          a.setAttribute("download", name);
          a.click();
        })
        .catch((err) => {});
    },
    // 删除
    handleDelete(row) {
      if (!this.myDel) {
        this.$message.error("您没有删除权限！");
        return;
      }
      this.$confirm(`确认删除${row.originalName}吗?`, "删除", {
        dangerouslyUseHTMLString: true,
        cancelButtonText: "取消",
        confirmButtonText: "确定",
        closeOnClickModal: false,
        cancelButtonClass: "btn-cancel",
        confirmButtonClass: "btn-confirm"
      }).then(() => {
        this.$axios.delete(`${this.baseURL}sys/sysFile/${row.id}`).then((res) => {
          if (res.status == 200) {
            this.$message.success("删除成功");
            this.getTableData();
          } else {
            this.$message.error(res.errMsg);
          }
        });
      });
    },
    // 表格条纹颜色
    tableRowClassName({ row, rowIndex }) {
      //条纹变色
      if (rowIndex % 2 == 1) {
        return "hui1";
      } else {
        return "lv1";
      }
    },
    // 切换分页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTableData();
    },
    // 关闭资料类型管理弹窗
    handleTypeDialogClose() {
      this.typeDialogVisible = false;
    },
    // 关闭上传资料弹窗
    handleUploadDialogClose() {
      this.uploadDialogVisible = false;
      this.uploadFileList = [];
    },
    // 关闭查看文件弹窗
    handlePdfClose() {
      this.pdfVisible = false;
    },
    // 上一页 下一页
    nextPdfPage(flag) {
      switch (flag) {
        case "next":
          if (this.currentPdfPage >= this.pagePdfCount) {
            this.currentPdfPage = this.pagePdfCount;
            return;
          }
          this.currentPdfPage++;
          break;
        case "prev":
          if (this.currentPdfPage <= 1) {
            this.currentPdfPage = 1;
            return;
          }
          this.currentPdfPage--;
      }
    },
    getSize(){
        this.tableHeight = window.innerHeight - 330
    }
  },
  components: {
    CommonPagination,
    pdf
  }
};
</script>

<style lang="scss" scoped>
.search-wrapper {
  line-height: 40px;
  justify-content: space-between;
  margin-top: 20px;
  ::v-deep .el-input .el-input__inner {
    height: 32px;
    line-height: 32px;
  }
  .el-form--inline .el-form-item {
    margin-bottom: 0;
  }
  .reset-btn {
    padding: 6px 28px;
    background: none;
    border: 1px solid #2667db;
    margin-right: 10px;
    margin-left: 20px;
  }
  .common-btn {
    padding: 6px 17px;
  }
}
.table-wrapper {
  margin-top: 24px;
  .operate-txt:not(.delete-btn) {
    margin-right: 16px;
  }
  /* .middle-txt {
            margin: 0 16px;
        } */
}
::v-deep .hui1 {
  background: #091d46 !important;
}
::v-deep .lv1 {
  background: #0c265a !important;
}
::v-deep .el-table td.el-table__cell,
::v-deep .el-table th.el-table__cell.is-leaf {
  font-size: 14px;
  text-align: center;
}
.common-page.pageMain {
  margin-top: 15px;
}
::v-deep .my-dialog.add-dialog .el-dialog {
  width: 560px !important;
}
::v-deep .my-dialog.upload-dialog .el-dialog {
  width: 500px !important;
}
::v-deep .my-dialog.pdf-dialog .el-dialog {
  width: 1600px !important;
  border-radius: 4px;
  img {
    width: 100%;
  }
}
::v-deep .my-dialog.pdf-dialog .el-dialog .pdf-dialog-tltle{
  display: flex;
  justify-content: space-between;
  padding: 24px 0 12px 0;
  border-bottom: 1px solid #7CA9DF99;
  span{
    font-size: 16px;
    color: #ffffffdb;
  }
  img{
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
 
}


// 'scale-one','scale-two','scale-three'
::v-deep .my-dialog.pdf-dialog.scale-one .el-dialog {
  width: 600px !important;
  img {
    width: 100%;
  }
}
::v-deep .my-dialog.pdf-dialog.scale-two .el-dialog {
  width: 1200px !important;
  img {
    width: 100%;
  }
}
::v-deep .my-dialog.pdf-dialog.scale-three .el-dialog {
  width: 1800px !important;
  img {
    width: 100%;
  }
}

::v-deep .my-dialog .el-dialog {
  border: none;
  background: linear-gradient(0deg, #0a173d 2%, #0d3772);
  .el-dialog__header {
    .el-dialog__title {
      font-size: 16px;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.86);
    }
  }
  .el-dialog__body {
    padding: 0 20px 40px 20px;
    color: #fff;
    .operate-wrapper {
      padding: 20px 20px 0 20px;
      .add-wrapper {
        text-align: right;
        color: #27a6ff;
        span:hover {
          cursor: pointer;
        }
      }
      .table-wrapper {
        .border-table-seliver {
          width: 100%;
          border-collapse: collapse;
          border: none;
          margin-bottom: 20px;

          tr {
            width: 100%;
            height: 40px;
          }
        }

        .border-table-seliver td {
          padding-left: 10px;
          border: 1px solid #3B68A9;
          text-align: center;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          span:hover {
            color: #00a9ff;
          }
          .el-input {
            width: 100%;
          }
          div.el-input .el-input__inner {
            width: 100%;
            border: 0;
            background: transparent !important;
          }
        }

        .border-table-seliver th {
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          height: 40px;
          font-size: 12px;
          padding-left: 10px;
          border: 1px solid #3B68A9;
        }
        .edit-btn {
          margin-right: 16px;
        }
        span:hover {
          cursor: pointer;
        }
      }
      .upload-btn {
        width: 88px;
        border: 1px solid #28a0d8;
        background: none;
        padding: 0;
        margin-right: 15px;
        color: #1dc7fc;
      }
      .tips {
        font-weight: 400;
        font-size: 12px;
        color: #9c9c9c;
        letter-spacing: 0.7px;
      }
      .btn-footer {
        display: flex;
        justify-content: center;
        margin-top: 30px;
        text-align: center;
        .el-button {
          width: 88px;
          height: 32px;
          line-height: 32px;
          padding: 0;
          margin: 0;
          font-size: 12px;
          font-weight: 500;
        }
        .save-btn {
          margin-right: 16px;
        }
        .cancel-btn {
          margin-left: 20px;
          background: none;
          border: 1px solid #2667db;
        }
      }
    }
  }
}
.pdf-wrapper {
  height: 70%;
  box-sizing: border-box;
  text-align: center;

  img {
    margin: auto;
  }

  .pdf-btm {
    padding-top: 20px;
    text-align: center;
  }

  .pdf-title {
    padding-bottom: 15px;
    text-align: center;
    position: relative;
    .scale {
      position: absolute;
      right: 20px;
      top: 0px;
      display: flex;
      height: 30px;
      width: 85px;
      justify-content: space-between;
      .scale-btn {
        background-color: #2667db;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 16px;
      }
    }
  }
  .pdf-title-warp {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .pdf-name{
      display: flex;
      align-items: center;
      img{
        width: 14px;
        height: 14px;
        margin-right: 12px;
      }
    }
    .pdf-action{
      display: flex;
      align-items: center;
      margin: 16px 0;
      .page-btn{
        height: 36px;
        line-height: 36px;border: 1px solid #2667DB;border-radius: 20px;background: #0d2b624d;
        padding: 0 18px;
        cursor: pointer;
      }
      .previous-action{
        margin-right: 32px;
      }
      .next-action{
       margin-left: 32px;
       margin-right: 32px;
      }

      .reduce-btn{
        width: 36px;
        height: 36px;
        background: #0d2b624d;
        border-radius: 20px;
        border: 1px solid #2667DB;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 32px;
        cursor: pointer;
      }
      .amplification-btn{
        width: 36px;
        height: 36px;
        background: #0d2b624d;
        border-radius: 20px;
        border: 1px solid #2667DB;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

    }

  }
  .pdf-warp{
      background: #131f461c;border: 1px solid #1F52B9;border-radius: 4px;
      padding: 0 500px;
      height: 60vh;
      display: flex;
      overflow-y: auto;
      span{
        width: 100%;
      }
    }
}
::v-deep .el-dialog__header{
  background-image: linear-gradient(94deg, #124183 0%, #123682 100%);
  border-radius: 4px 4px 0 0;
  border-bottom: 1px solid #7ca9df99;;
}
::v-deep .el-dialog__headerbtn {
  width: 24px;
  height: 24px;
  background: rgba(204, 218, 255, 0.2);
  border-radius: 50%;
  color: #ffffff;
  font-size: 14px;
  top: 14px;
} 
::v-deep .el-icon-close:before {
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 0;
}

::v-deep .el-dialog__body{
  background-image: linear-gradient(0deg, #0A173D 0%, #124286 100%);
  border-radius: 4px;
  border:none;
}

::v-deep .el-dialog__header{
  background-image: linear-gradient(94deg, #124183 0%, #123682 100%);
  border-radius: 4px 4px 0 0;
  border-bottom: 1px solid #7ca9df99;;
}
::v-deep .el-dialog__headerbtn {
  width: 24px;
  height: 24px;
  background: rgba(204, 218, 255, 0.2);
  border-radius: 50%;
  color: #ffffff;
  font-size: 14px;
  top: 14px;
} 
::v-deep .el-icon-close:before {
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 0;
}

::v-deep .el-dialog__body{
  background-image: linear-gradient(0deg, #0A173D 0%, #124286 100%);
  border-radius: 4px !important;
}
</style>
